import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { AppDetails } from '@core/app_details';
import { useRoutes } from '@hooks/routes.hook';
import { AvailableTranslationLang, RouterAccessStatus } from '@project/enums';
import { AppActions } from '@store/items/app';
import { CustomersActions } from '@store/items/customers';

const mapState = ({ app, customers }: RootState) => ({
  currentLang: customers.lang,
});

const mapDispatch = {
  requestRegisterCustomerAsOnline: () =>
    AppActions.requestRegisterCustomerAsOnline(),
  requestGetLangDetails: () => CustomersActions.requestGetLangDetails(),
  requestForFavorites: () => CustomersActions.requestForFavorites(),
  requestUpdateLangDetails: (payload: AvailableTranslationLang) =>
    CustomersActions.requestUpdateLangDetails(payload),
};

function Component({
  currentLang,
  requestGetLangDetails,
  requestForFavorites,
  requestUpdateLangDetails,
  requestRegisterCustomerAsOnline,
}: RouterProps): Nullable<JSX.Element> {
  const { i18n } = useTranslation();
  const [isLangLoaded, setIsLangLoaded] = useState(false);
  let currentRouteAccess: RouterAccessStatus = RouterAccessStatus.UNAUTHORIZED;

  useEffect(() => {
    requestRegisterCustomerAsOnline();
    requestGetLangDetails();
    requestForFavorites();
  }, []);

  useEffect(() => {
    if (currentLang !== null) {
      const pathParticles = location.pathname.split('/');
      const currentPathLang = (
        pathParticles[1] === '' ||
        !AppDetails.supportedLangs.includes(
          pathParticles[1] as AvailableTranslationLang,
        )
          ? AppDetails.fallbackAppLang
          : pathParticles[1]
      ) as AvailableTranslationLang;

      const isCurrentPathSupportedByLangs =
        AppDetails.supportedLangs.includes(currentPathLang);

      if (isCurrentPathSupportedByLangs) {
        requestUpdateLangDetails(currentPathLang);
        i18n.changeLanguage(currentPathLang);
      }

      setIsLangLoaded(true);
    }
  }, [currentLang]);

  const currentRoutes = useRoutes(currentRouteAccess);

  return isLangLoaded ? <BrowserRouter>{currentRoutes}</BrowserRouter> : null;
}

export const RouterConnector = connect(mapState, mapDispatch);

export const Router = RouterConnector(Component);
