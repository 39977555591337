import { ForkEffect, takeEvery } from 'redux-saga/effects';

import { ReduxActionType } from '@project/enums';
import {
  getAllNews,
  getArticlesRecommendations,
  getExactArticle,
  getPriorityNews,
} from '@store/side_effects/articles';
import { getAllCommonQuestions } from '@store/side_effects/common_questions';
import {
  acceptTermsOfUsage,
  subscribeCustomer,
  getCustomersLangDetails,
  getCustomersTermsAcceptance,
  updateCustomersLangDetails,
  getCustomerFavorites,
  addCustomerFavorite,
  deleteCustomerFavorite,
  requestFormFromCustomer,
} from '@store/side_effects/customers';
import {
  getAllProperties,
  getExactProperty,
  getFavoritesProperty,
  getPriorityProperties,
} from '@store/side_effects/properties';
import { getLatestReviews } from '@store/side_effects/reviews';
import {
  getAllServices,
  getExactService,
  getPriorityServices,
  getServicesRecommendations,
} from '@store/side_effects/services';
import { onStatsInitWorker } from '@store/side_effects/stats';

export function* rootSagaWatcher(): Generator<ForkEffect<void>> {
  yield takeEvery(ReduxActionType.STATS_INIT, onStatsInitWorker);

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_GET_LANG_DETAILS,
    getCustomersLangDetails,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_UPDATE_LANG_DETAILS,
    updateCustomersLangDetails,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_GET_TERMS_ACCEPTANCE,
    getCustomersTermsAcceptance,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_FOR_ACCEPT_TOU,
    acceptTermsOfUsage,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_SUBSCRIBE,
    subscribeCustomer,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_FORM,
    requestFormFromCustomer,
  );

  yield takeEvery(ReduxActionType.ARTICLES_REQUEST_GET_ALL_NEWS, getAllNews);

  yield takeEvery(
    ReduxActionType.ARTICLES_REQUEST_GET_PRIORITY_NEWS,
    getPriorityNews,
  );

  yield takeEvery(
    ReduxActionType.ARTICLES_REQUEST_GET_EXACT_ARTICLE,
    getExactArticle,
  );

  yield takeEvery(
    ReduxActionType.ARTICLES_REQUEST_GET_RECOMMENDATION,
    getArticlesRecommendations,
  );

  yield takeEvery(ReduxActionType.REVIEWS_REQUEST_GET_LATEST, getLatestReviews);

  yield takeEvery(ReduxActionType.SERVICES_REQUEST_GET_ALL, getAllServices);

  yield takeEvery(
    ReduxActionType.SERVICES_REQUEST_GET_PRIORITY,
    getPriorityServices,
  );

  yield takeEvery(ReduxActionType.SERVICES_REQUEST_GET_EXACT, getExactService);

  yield takeEvery(
    ReduxActionType.SERVICES_REQUEST_GET_RECOMMENDATION,
    getServicesRecommendations,
  );

  yield takeEvery(
    ReduxActionType.COMMON_QUESTIONS_REQUEST_GET_ALL,
    getAllCommonQuestions,
  );

  yield takeEvery(
    ReduxActionType.PROPERTIES_REQUEST_GET_PRIORITY,
    getPriorityProperties,
  );

  yield takeEvery(ReduxActionType.PROPERTIES_REQUEST_GET_ALL, getAllProperties);

  yield takeEvery(
    ReduxActionType.PROPERTIES_REQUEST_GET_EXACT,
    getExactProperty,
  );

  yield takeEvery(
    ReduxActionType.PROPERTIES_REQUEST_GET_FAVORITES,
    getFavoritesProperty,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_FOR_FAVORITES,
    getCustomerFavorites,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_ADD_FAVORITE,
    addCustomerFavorite,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_DELETE_FAVORITE,
    deleteCustomerFavorite,
  );
}
