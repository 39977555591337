import '@scss/index.scss';

import '@utils/prototype';
import '@translations/module';

import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';

import { AppDetails } from '@core/app_details';
import { store } from '@store/store';
import { Router } from 'routing';

// const ACCESS_CODE = 'yQA8572kVeE4ZGbuj3NaSfFUxvTqD6LXw9zMWBJhcptYsHRPdg';

// const code = AppDetails.isDevMode
//   ? ACCESS_CODE
//   : prompt('Could you confirm your access? -_-');

// TODO: Un comment it and more protect to
// disable opening inspector and ctrl + a

// if (AppDetails.isProdMode) {
//   window.addEventListener('contextmenu', (e) => e.preventDefault());

//   // @ts-ignore
//   document.querySelector('*')!.style.userSelect = 'none';
// }

// if (code === ACCESS_CODE) {
ReactDOM.createRoot(document.getElementById('app')!).render(
  <Provider store={store}>
    <Router />
    <Toaster />
  </Provider>,
);
// }
