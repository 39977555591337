import { AppDetails } from '@core/app_details';
import {
  AppError,
  AppLoader,
  AppPassedAction,
  ReduxActionType,
} from '@project/enums';

const initialState: AppState = {
  hasUiLocked: false,
  errors: [],
  loaders: [
    AppLoader.NAVBAR_LOADING,
    AppLoader.EXACT_ARTICLE_LOADING,
    AppLoader.EXACT_SERVICE_LOADING,
    AppLoader.SERVICE_RECOMMENDATIONS_LOADING,
  ],
  passedActions: [],
  dynamicPaths: null,
};

if (!AppDetails.isDevMode) {
  initialState.loaders.push(AppLoader.SPLASH_LOADING);
}

export function appReducer(
  state: AppState = initialState,
  action: DispatchWithPayload<
    boolean | AppError | AppLoader | AppPassedAction | MapValueToTranslation
  >,
): AppState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.APP_LOCK_UI:
      return {
        ...state,
        hasUiLocked: true,
      };

    case ReduxActionType.APP_UNLOCK_UI:
      return {
        ...state,
        hasUiLocked: false,
      };

    case ReduxActionType.APP_SET_ERROR:
      return {
        ...state,
        errors: Array.from(
          new Set([...state.errors, action.payload as AppError]),
        ),
      };

    case ReduxActionType.APP_RESET_ERROR:
      return {
        ...state,
        errors: state.errors.filter(
          (error) => error !== (action.payload as AppError),
        ),
      };

    case ReduxActionType.APP_SET_LOADER:
      return {
        ...state,
        loaders: Array.from(
          new Set([...state.loaders, action.payload as AppLoader]),
        ),
      };

    case ReduxActionType.APP_RESET_LOADER:
      return {
        ...state,
        loaders: state.loaders.filter(
          (loader) => loader !== (action.payload as AppLoader),
        ),
      };

    case ReduxActionType.APP_SET_PASSED_ACTION:
      return {
        ...state,
        passedActions: Array.from(
          new Set([...state.passedActions, action.payload as AppPassedAction]),
        ),
      };

    case ReduxActionType.APP_RESET_PASSED_ACTION:
      return {
        ...state,
        passedActions: state.passedActions.filter(
          (passedAction) =>
            passedAction !== (action.payload as AppPassedAction),
        ),
      };

    case ReduxActionType.APP_SET_DYNAMIC_PATH:
      return {
        ...state,
        dynamicPaths: {
          ...(action.payload as MapValueToTranslation),
        },
      };

    case ReduxActionType.APP_RESET_DYNAMIC_PATH:
      return {
        ...state,
        dynamicPaths: null,
      };
  }
}

export class AppActions {
  public static requestRegisterCustomerAsOnline(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.STATS_INIT,
    };
  }

  public static lockUI(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.APP_LOCK_UI,
    };
  }

  public static unlockUI(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.APP_UNLOCK_UI,
    };
  }

  public static setError(payload: AppError): DispatchWithPayload<AppError> {
    return {
      type: ReduxActionType.APP_SET_ERROR,
      payload,
    };
  }

  public static resetError(payload: AppError): DispatchWithPayload<AppError> {
    return {
      type: ReduxActionType.APP_RESET_ERROR,
      payload,
    };
  }

  public static setLoader(payload: AppLoader): DispatchWithPayload<AppLoader> {
    return {
      type: ReduxActionType.APP_SET_LOADER,
      payload,
    };
  }

  public static resetLoader(
    payload: AppLoader,
  ): DispatchWithPayload<AppLoader> {
    return {
      type: ReduxActionType.APP_RESET_LOADER,
      payload,
    };
  }

  public static setPassedAction(
    payload: AppPassedAction,
  ): DispatchWithPayload<AppPassedAction> {
    return {
      type: ReduxActionType.APP_SET_PASSED_ACTION,
      payload,
    };
  }

  public static resetPassedAction(
    payload: AppPassedAction,
  ): DispatchWithPayload<AppPassedAction> {
    return {
      type: ReduxActionType.APP_RESET_PASSED_ACTION,
      payload,
    };
  }

  public static setDynamicPath(
    payload: MapValueToTranslation,
  ): DispatchWithPayload<MapValueToTranslation> {
    return {
      type: ReduxActionType.APP_SET_DYNAMIC_PATH,
      payload,
    };
  }

  public static resetDynamicPath(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.APP_RESET_DYNAMIC_PATH,
    };
  }
}
