import { AvailableTranslationLang, RuntimeMode } from '@project/enums';

export class AppDetails {
  public static isDevMode: boolean = process.env.NODE_ENV!.includes(
    RuntimeMode.DEV,
  );
  public static isProdMode: boolean = !AppDetails.isDevMode;

  public static apiHost: string = `https://${
    AppDetails.isDevMode ? 'localhost:3001' : 'api.roso.properties'
  }/`;

  public static defaultAppearanceDuration: number = 2500;
  public static defaultCountersDuration: number = 5;

  public static defaultConnectWithUsNotifications: number = 1000 * 60 * 3;

  public static fallbackAppLang = AvailableTranslationLang.EN_GB;

  public static licenseAmi = '21014';
  public static achievements: Achievements = {
    houses: 1400,
    years: 10,
    customers: 120,
  };

  public static facebookUrl = 'https://www.facebook.com/nataliya.roso';
  public static instagramUrl =
    'https://www.instagram.com/nataliya.roso.properties/';
  public static linkedInUrl =
    'https://www.linkedin.com/in/nataliya-rososhenko/';
  public static youtubeUrl =
    'https://www.youtube.com/@nataliya-roso-properties';

  public static fullNamePlaceholder = 'Alex Bond';
  public static emailPlaceholder = 'alex.bond@gmail.com';
  public static phoneNumberPlaceholder = '+380 (50) 000 00 00';
  public static subjectPlaceholder = 'Subject';
  public static descriptionPlaceholder = 'Description';

  public static complaintBook = 'https://www.livroreclamacoes.pt/Inicio/';

  public static supportedLangs: AvailableTranslationLang[] = [
    AvailableTranslationLang.EN_GB,
    AvailableTranslationLang.EN_US,
    AvailableTranslationLang.PT,
    AvailableTranslationLang.UK,
    AvailableTranslationLang.FR,
    AvailableTranslationLang.IE,
    AvailableTranslationLang.DE,
    AvailableTranslationLang.CN,
    // AvailableTranslationLang.RU,
  ];

  public static displayedLangs: AvailableTranslationLang[] = [
    AvailableTranslationLang.EN_GB,
    AvailableTranslationLang.EN_US,
    AvailableTranslationLang.PT,
    AvailableTranslationLang.UK,
    AvailableTranslationLang.FR,
    AvailableTranslationLang.IE,
    AvailableTranslationLang.DE,
    AvailableTranslationLang.CN,
    // AvailableTranslationLang.RU,
  ];

  public static langs: Lang[] = [
    {
      code: AvailableTranslationLang.EN_GB,
      icon: require('../assets/svg/flags/en-GB.svg').default,
      original: 'English GB',
    },
    {
      code: AvailableTranslationLang.EN_US,
      icon: require('../assets/svg/flags/en-US.svg').default,
      original: 'English US',
    },
    {
      code: AvailableTranslationLang.PT,
      icon: require('../assets/svg/flags/pt.svg').default,
      original: 'Português',
    },
    {
      code: AvailableTranslationLang.UK,
      icon: require('../assets/svg/flags/uk.svg').default,
      original: 'Українська',
    },
    {
      code: AvailableTranslationLang.FR,
      icon: require('../assets/svg/flags/fr.svg').default,
      original: 'Français',
    },
    {
      code: AvailableTranslationLang.IE,
      icon: require('../assets/svg/flags/ie.svg').default,
      original: 'Gaeilge',
    },
    {
      code: AvailableTranslationLang.DE,
      icon: require('../assets/svg/flags/de.svg').default,
      original: 'Deutsch',
    },
    {
      code: AvailableTranslationLang.CN,
      icon: require('../assets/svg/flags/cn.svg').default,
      original: '中文',
    },
    // {
    //   code: AvailableTranslationLang.RU,
    //   icon: require('../assets/svg/flags/ru.svg').default,
    //   original: 'Русский',
    // },
  ];

  public static landAreaLimitation: DataRange = [1, 50000];
  public static buildingAreaLimitation: DataRange = [1, 5000];
  public static bathroomsLimitation: DataRange = [1, 10];
  public static priceLimitation: DataRange = [1, 20000000];
  public static bedRoomsLimitation: DataRange = [1, 10];
}
