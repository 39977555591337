import { Suspense, lazy, ComponentType } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Spinner from '@components/spinner';
import { AppDetails } from '@core/app_details';
import { AppRoutes } from '@project/enums';

const MainPage = lazy(() =>
  import('@pages/main.page').then((module) => ({
    default: module.MainPage as ComponentType,
  })),
);
const PropertiesPage = lazy(() =>
  import('@pages/properties.page').then((module) => ({
    default: module.PropertiesPage as ComponentType,
  })),
);
const ArticlePage = lazy(() =>
  import('@pages/article.page').then((module) => ({
    default: module.ArticlePage as ComponentType,
  })),
);
const ServicePage = lazy(() =>
  import('@pages/service.page').then((module) => ({
    default: module.ServicePage as ComponentType,
  })),
);
const ServicesPage = lazy(() =>
  import('@pages/services.page').then((module) => ({
    default: module.ServicesPage as ComponentType,
  })),
);
const NewsPage = lazy(() =>
  import('@pages/news.page').then((module) => ({
    default: module.NewsPage as ComponentType,
  })),
);
const PropertyPage = lazy(() =>
  import('@pages/property.page').then((module) => ({
    default: module.PropertyPage as ComponentType,
  })),
);
const WhoIsNrPage = lazy(() =>
  import('@pages/who_is_nr.page').then((module) => ({
    default: module.WhoIsNrPage as ComponentType,
  })),
);
const FaqPage = lazy(() =>
  import('@pages/faq.page').then((module) => ({
    default: module.FaqPage as ComponentType,
  })),
);
const AboutAlgarvePage = lazy(() =>
  import('@pages/about_algarve.page').then((module) => ({
    default: module.AboutAlgarvePage as ComponentType,
  })),
);
const FavoritePropertiesPage = lazy(() =>
  import('@pages/favorite_properties.page').then((module) => ({
    default: module.FavoritePropertiesPage as ComponentType,
  })),
);
const TncPage = lazy(() =>
  import('@pages/tnc.page').then((module) => ({
    default: module.TncPage as ComponentType,
  })),
);
const PrivacyPolicyPage = lazy(() =>
  import('@pages/privacy_policy.page').then((module) => ({
    default: module.PrivacyPolicyPage as ComponentType,
  })),
);

export function defaultRouter(): JSX.Element {
  const supportedLangsWithoutDetails = AppDetails.supportedLangs.filter(
    (langCode) => langCode !== AppDetails.fallbackAppLang,
  );

  let mapPathToElementMultiLangs: RouterPathToComponentMultiLangMapper = {};
  const mapPathToElement: RouterPathToComponentMapper = {
    [AppRoutes.ROOT]: <MainPage />,
    [AppRoutes.PROPERTIES]: <PropertiesPage />,
    [AppRoutes.ARTICLE]: <ArticlePage />,
    [AppRoutes.SERVICE]: <ServicePage />,
    [AppRoutes.SERVICES]: <ServicesPage />,
    [AppRoutes.NEWS]: <NewsPage />,
    [AppRoutes.PROPERTY]: <PropertyPage />,
    [AppRoutes.WHO_IS_NR]: <WhoIsNrPage />,
    [AppRoutes.FAQ]: <FaqPage />,
    [AppRoutes.ABOUT_ALGARVE]: <AboutAlgarvePage />,
    [AppRoutes.FAVORITE_PROPERTIES]: <FavoritePropertiesPage />,
    [AppRoutes.TNC]: <TncPage />,
    [AppRoutes.PRIVACY_POLICY]: <PrivacyPolicyPage />,
  };

  for (const [path, element] of Object.entries(mapPathToElement)) {
    for (const langCode of supportedLangsWithoutDetails) {
      mapPathToElementMultiLangs[`/${langCode}${path}`] = element;
    }

    mapPathToElementMultiLangs[path] = element;
  }

  return (
    <Routes>
      {Object.entries(mapPathToElementMultiLangs).map(([path, element]) => (
        <Route
          key={path}
          path={path}
          element={<Suspense fallback={<Spinner />}>{element}</Suspense>}
        />
      ))}
      <Route path={AppRoutes.ANY} element={<Navigate to={AppRoutes.ROOT} />} />
    </Routes>
  );
}
