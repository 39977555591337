import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import CN from '@assets/translations/cn.json';
import DE from '@assets/translations/de.json';
import EN_GB from '@assets/translations/en-GB.json';
import EN_US from '@assets/translations/en-US.json';
import FR from '@assets/translations/fr.json';
import IE from '@assets/translations/ie.json';
import PT from '@assets/translations/pt.json';
// import RU from '@assets/translations/ru.json';
import UK from '@assets/translations/uk.json';
import { AvailableTranslationLang } from '@project/enums';

i18n.use(initReactI18next).init({
  lng: AvailableTranslationLang.EN_GB,
  // fallbackLng: [AvailableTranslationLang.EN_GB],
  resources: {
    [AvailableTranslationLang.EN_GB]: {
      translation: EN_GB,
    },
    [AvailableTranslationLang.DE]: {
      translation: DE,
    },
    [AvailableTranslationLang.EN_US]: {
      translation: EN_US,
    },
    [AvailableTranslationLang.FR]: {
      translation: FR,
    },
    [AvailableTranslationLang.PT]: {
      translation: PT,
    },
    [AvailableTranslationLang.UK]: {
      translation: UK,
    },
    [AvailableTranslationLang.CN]: {
      translation: CN,
    },
    [AvailableTranslationLang.IE]: {
      translation: IE,
    },
    // [AvailableTranslationLang.RU]: {
    //   translation: RU,
    // },
  },
  interpolation: {
    escapeValue: false,
  },
});

export const TranslationsModule = i18n;
