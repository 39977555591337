import { call } from 'redux-saga/effects';
import { io } from 'socket.io-client';

import { showToast } from '@components/toast';
import { AppDetails } from '@core/app_details';
import { MessageType } from '@project/enums';

export function* onStatsInitWorker() {
  try {
    yield call(registerAsOnline);
  } catch (e: any) {
    showToast({
      type: MessageType.ERROR,
      message: e,
    });
  }
}

async function registerAsOnline(): Promise<void> {
  const userIp = await fetch('https://api.ipify.org?format=json')
    .then((data) => data.json())
    .then((data) => data.ip);

  io(`${AppDetails.apiHost}api/stats/customers-online`, {
    extraHeaders: {
      uniqueuserhash: window.navigator.userAgent + userIp,
    },
  });
}
